import React from 'react'
import { styled } from "@mui/system"
import { Typography, useMediaQuery } from "@mui/material"
import LinkIcon from '../../static/svg/link.svg'
import GridLines from '../gridLines'


const Wraper = styled('div')(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "1280px",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
  "@media (max-width:576px)": {
    margin: "0px",
  }
}))

const WrapperTitle = styled("div")(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 28,
  marginButtom: 20
}))

const WrapperSubTitle = styled("div")(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 46,
  "@media (max-width:576px)": {
    width: "100%",
    textAlign: 'center',
    padding: "0 20px",
  }
}))

const Body = styled("div")(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  rowGap: "25px",
  marginBottom: 30,
  "@media (max-width:576px)": {
    padding: "0 20px",
    rowGap: "17px",
    marginBottom: 41,
  }
}))

const Row = styled("div")(({ theme }) => ({
  width: '100%',
  display: "grid",
  paddingTop: 31,
  gridTemplateColumns: "repeat(6, 1fr)",
  paddingBottom: "55px",

  "@media (max-width:576px)": {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'stretch',
    alignItems: 'flex-start',
    paddingTop: 25,
    paddingBottom: "38px",
  }
}))

const Name = styled(Typography)(({ theme }) => ({
  fontFamily: 'Heiti TC',
  fontWeight: "400",
  fontSize: "49px",
  lineHeight: "130%",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 90,
  textTransform: 'uppercase',
  gridColumnStart: 1,
  gridColumnEnd: 3,
  paddingTop: 5,
  "@media (max-width:576px)": {
    marginRight: "5.714%",
    paddingTop: 0,
  }
}))

const Description = styled("div")(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '35vw',
  gridColumnStart: 3,
  gridColumnEnd: 6,
  maxWidth: 600,
  "@media (max-width:576px)": {
    width: '90vw',
    marginTop: 17,
  }
}))

const Check = styled("a")(({ theme }) => ({
  height: "fit-content",
  width: "fit-content",
  display: 'flex',
  justifySelf: 'flex-end',
  alignItems: 'flex-start',
  marginBottom: 20,
  cursor: 'pointer',
  color: '#0870E0',
  textDecoration: 'none',
  gridColumnStart: 6,
  gridColumnEnd: 7,
  paddingTop: 10,
  borderBottom: "1px solid #FFF",
  "&:hover": {
    borderBottom: '1px solid #0870E0',
  },
  "@media (max-width:576px)": {
    marginTop: 20,
    marginBottom: 0,
  }
}))
const TextHeader =  styled(Typography)({
  fontSize: "66px",
  lineHeight: "135%",
  marginRight: 0,
  "@media (max-width:576px)": {
    width: '100%',
    letterSpacing: "0.01em",
    fontSize: "10.256vw",
  }
})

const TextBlock =  styled(Typography)({
  fontFamily: 'Merriweather',
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "21px",
  lineHeight: "190%",
  marginRight: 0,
  textAlign: "left",
  "@media (max-width:576px)": {
    letterSpacing: "0.001em",
    width: '100%',
    fontSize: "4.615vw",
  }
})


const TextToolName =  styled(Typography)({
  fontFamily: 'Heiti TC',
  fontWeight: "400",
  fontSize: "49px",
  lineHeight: "130%",
  letterSpacing: "-0.01em",
  textTransform: "uppercase",
  color: "#0870E0",
  marginRight: 0,
  "@media (max-width:576px)": {
    letterSpacing: "0.001em",
    width: '100%',
    fontSize: "11.282vw",
  }
})

const LinkIconBox =  styled('div')({
  width: "21px",
  height: "21px",
  marginTop: 4,
  "@media (max-width:576px)": {
    width: "17px",
    height: "17px",
    marginTop: 3,
  }
})

const LinkText =  styled(Typography)({
  fontSize: "22px",
  "@media (max-width:576px)": {
    fontSize: "18px",
  }
})



export default function Hero({items}){
  const isMobile = useMediaQuery("(max-width: 576px)")

  return (
    <Wraper>
      <WrapperTitle>
        <TextHeader variant='h1' >Tools</TextHeader>
      </WrapperTitle>
      <WrapperSubTitle>
        <TextBlock variant='body1'>
          We <span style={{color: '#0870E0'}}>strive to invest into global startup{isMobile ? <br/> : null} community</span> and dedicate efforts to <br/> share knowledge and develop{isMobile ? <br/> : null} useful instruments for founders{isMobile ? <br/> : null} and investors.
        </TextBlock>
      </WrapperSubTitle>
      <GridLines
        styles={{
            height: 174,
            heightMob: 60,
            marginTop: 25,
            marginTopMob: 10,
            color: "#F8F8F8",
        }}
      />
      <Body>
          {items.map((item, i) =>
            <>
              <Row style={{borderBottom: i !== items.length - 1 ? "1px solid #0870E0" : "none"}}>
                <Name>
                  <TextToolName variant={'h2'}>{item.title}</TextToolName>
                </Name>
                <Description>
                  <TextBlock variant={'body1'}>{item.description}</TextBlock>
                </Description>
                <Check href={item.link} target='_blank' rel="noopener noreferrer">
                  <LinkText variant={'body1'}>
                    CHECK IT OUT
                    </LinkText>
                    <LinkIconBox>
                      <LinkIcon style={{width: "100%", height: "100%"}}/>
                    </LinkIconBox>
                </Check>
              </Row>
            </>
          )}
      </Body>
      <GridLines
        styles={{
            height: 87,
            heightMob: 30,
            color: "#F8F8F8",
        }}
      />

    </Wraper>
  )
}