import React from 'react'
import { graphql } from "gatsby"
import Tools from '../components/tools'
import Layout from "../components/layout"
import Seo from "../components/seo"

const ToolsPage = ({ data }) => {
  return (
    <Layout headerDark={false}>
      <Seo title="Tools" description={`We strive to invest into global startup community and dedicate efforts to share knowledge and develop useful instruments for founders and investors.`} />
      <Tools items={data.strapiTool.items}/>
    </Layout>
  )
}

export default ToolsPage

export const query = graphql`
  query tool {
    strapiTool {
      items {
        title
        description 
        link
      }
    }
  }
`